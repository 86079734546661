import React from 'react';
import { graphql, PageProps, Script } from 'gatsby';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image';
import MoreInformationSection, {
  MoreInformationSectionType,
} from 'components/organisms/more-information-section';
import SimpleWrapper from 'components/molecules/simple-wrapper';
import Breadcrumb from 'components/atoms/breadcrumb';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      moreInformationSection: MoreInformationSectionType;
    };
  };
  aboutKestriaPage: {
    aboutKestriaData: {
      bannerQuaternary: BannerPrimaryType;
    };
  };
};

const AboutKestriaPage = ({
  pageContext,
  data: {
    commonComponents: {
      commonComponentsData: { moreInformationSection },
    },
    aboutKestriaPage: {
      aboutKestriaData: {
        bannerQuaternary: { heading, image, text },
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      showDots
      titleTop={heading}
      subtitleTop={text}
      image={image}
      positionTitle="left"
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <SimpleWrapper>
      <div className="js-kestria-for-local-webs"> </div>
    </SimpleWrapper>
    <MoreInformationSection {...moreInformationSection} />
    <Script src="https://kestria.com/for-local-webs/about_kestria.js?map_variant=interactive&css=layout" />
  </Layout>
);

export const query = graphql`
  query AboutKestriaPage($commonComponentsSlug: String!) {
    aboutKestriaPage: wpPage(slug: { eq: "kestria" }) {
      aboutKestriaData {
        bannerQuaternary {
          heading
          text
          image {
            ...WpImageFragment
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        moreInformationSection {
          heading
          cardList {
            text
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  id
                  uri
                }
              }
              external {
                target
                url
              }
            }
            icon {
              name
            }
          }
        }
      }
    }
  }
`;

export default AboutKestriaPage;
