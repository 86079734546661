import React from 'react';
import { DefaultType } from 'types/default';

import { StyledContent, StyledWrapper } from './simple-wrapper.styles';

export type SimpleWrapperType = {
  children?: React.ReactNode;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
  gapMobile?: string;
  flexDirection?: string;
} & DefaultType;

const SimpleWrapper = ({
  children,
  margin,
  mobileMargin,
  padding,
  mobilePadding,
  bgColor,
  justifyContent,
  alignItems,
  gap,
  gapMobile,
  flexDirection,
  maxWidth,
  width,
}: SimpleWrapperType) => (
  <StyledWrapper
    width={width}
    maxWidth={maxWidth}
    margin={margin}
    mobileMargin={mobileMargin}
    padding={padding}
    mobilePadding={mobilePadding}
    bgColor={bgColor}
  >
    <StyledContent
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gap={gap}
      gapMobile={gapMobile}
    >
      {children}
    </StyledContent>
  </StyledWrapper>
);

export default SimpleWrapper;
