import styled from 'styled-components';
import { breakpoint } from 'theme';

export const StyledWrapper = styled.div<{
  bgColor?: string;
  margin?: string;
  mobileMargin?: string;
  padding?: string;
  mobilePadding?: string;
  flexDirection?: string;
  maxWidth?: string;
  width?: string;
}>`
  position: relative;
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};

  background-color: ${({ bgColor }) => bgColor};

  padding: ${({ theme, mobilePadding }) => mobilePadding || `${theme.gap.sm}`};
  margin: ${({ mobileMargin }) => mobileMargin};

  ${breakpoint.md} {
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
  }

  & .breadcrumb__separator {
    color: ${({ theme }) => theme.colors.whiteAlpha40};
  }

  & .breadcrumb__list__item a {
    color: ${({ theme }) => theme.colors.white};
  }

  & .breadcrumb__list__item a[aria-current='page'] {
    color: ${({ theme }) => theme.colors.whiteAlpha40};
  }
`;

export const StyledContent = styled.div<{
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
  gapMobile?: string;
  flexDirection?: string;
}>`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.wrapper};
  display: flex;

  gap: ${({ gapMobile }) => gapMobile};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-wrap: wrap;

  ${breakpoint.md} {
    gap: ${({ gap }) => gap};
  }
`;
